/* eslint-disable sort-keys-fix/sort-keys-fix */
import React, { useRef } from "react";
import { Grid, GridDirection } from "@mui/material";
import { SectionContainer } from "../containers";
import { useHasBeenAlmostVisible } from "../hooks";
import { Media, MediaPlacementType, PpMedia } from "../media";
import {
  Actions,
  Description,
  SectionName,
  TextContainer,
  Title,
  TitleBackground,
  TitleContainer,
  TitleImage,
} from "./styles";

type PpFounder = {
  actions?: JSX.Element | Array<JSX.Element>;
  alignCenter: boolean;
  darkMode?: boolean;
  description: string;
  media?: PpMedia["media"];
  mediaPlacement?: string;
  sectionName?: string;
  title?: string;
  titleBackground?: string;
  titleImage?: string;
};

export function Founder({
  actions,
  alignCenter = true,
  darkMode,
  description,
  media,
  mediaPlacement,
  sectionName,
  title,
  titleBackground,
  titleImage,
  ...rest
}: PpFounder): React.ReactElement {
  const elementRef = useRef<HTMLDivElement>(null);
  const shouldRenderContent = useHasBeenAlmostVisible(
    elementRef,
    "0px 0px 200px 0px"
  );

  const direction = {
    xs: "column-reverse" as GridDirection,
    md: "row" as GridDirection,
  };

  switch (mediaPlacement) {
    case MediaPlacementType.RIGHT:
      direction.md = "row-reverse";
      break;
    case MediaPlacementType.TOP:
      direction.xs = "column";
      break;
    default:
      break;
  }

  return (
    <SectionContainer {...rest}>
      <Grid
        ref={elementRef}
        container
        alignItems="center"
        direction={direction}
        spacing={{ xs: 5, md: 8 }}
      >
        <Grid item zeroMinWidth md={5} style={{ maxWidth: "100%" }}>
          <Media media={media} />
        </Grid>
        <TextContainer
          container
          item
          $alignCenter={alignCenter}
          alignItems={{
            xs: "center",
            md: alignCenter ? "center" : "flex-start",
          }}
          direction="column"
          md={7}
        >
          {sectionName && (
            <Grid item>
              <SectionName
                gutterBottom
                color="primary.light"
                component="p"
                variant="section--name"
              >
                {sectionName}
              </SectionName>
            </Grid>
          )}
          {titleImage && (
            <Grid item>
              <TitleImage src={titleImage} />
            </Grid>
          )}
          {title && (
            <TitleContainer item>
              <Title
                color={darkMode ? "primary.contrastText" : "primary"}
                component="h2"
                text={title}
                variant="h2"
              />
              {titleBackground && shouldRenderContent && (
                <TitleBackground
                  $darkMode={darkMode}
                  className={`icon-${titleBackground}`}
                />
              )}
            </TitleContainer>
          )}
          <Grid item>
            <Description
              gutterBottom
              color={darkMode ? "primary.contrastText" : "primary"}
              text={description}
              variant="body1"
            />
          </Grid>
          {actions && <Actions item>{actions}</Actions>}
        </TextContainer>
      </Grid>
    </SectionContainer>
  );
}
