import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { RichText } from "../textFields";
import { shouldForwardProp } from "../utils";

export const SectionName = styled(Typography)`
  position: relative;
  z-index: 5;
`;

export const TitleContainer = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    position: relative;
    min-width: 80%;
    margin-bottom: ${theme.spacing(1)};
  `}
`;

export const Title = styled(RichText)`
  position: relative;
  z-index: 5;
  width: 100%;
`;

type PpDarkMode = {
  $darkMode: boolean;
};

export const TitleBackground = styled("span", {
  shouldForwardProp,
})<PpDarkMode>`
  ${({ $darkMode, theme }): SerializedStyles => css`
    display: none;
    ${theme.breakpoints.up("md")} {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 4;
      &:before {
        font-size: 125px;
        color: ${$darkMode
          ? theme.palette.site.founderBackground.dark
          : theme.palette.site.founderBackground.light} !important;
      }
    }
  `}
`;

export const TitleImage = styled("img")`
  ${({ theme }): SerializedStyles => css`
    width: 100%;
    margin-bottom: ${theme.spacing(2)};
    ${theme.breakpoints.up("md")} {
      width: 80%;
    }
  `}
`;

export const Description = styled(RichText)`
  position: relative;
  z-index: 6;
`;

export const Actions = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(3)};
  `};
`;

type PpAlignCenter = {
  $alignCenter: boolean;
};

export const TextContainer = styled(Grid, { shouldForwardProp })<PpAlignCenter>`
  ${({ $alignCenter, theme }): SerializedStyles => css`
    text-align: center;
    ${theme.breakpoints.up("md")} {
      text-align: ${$alignCenter ? "center" : "left"};
    }
  `};
`;
